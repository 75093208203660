@font-face {
	font-family: "CircularStd";
	src: url("./assets/fonts/CircularStd-Book.otf");
}

@font-face {
	font-family: "CircularStd";
	src: url("./assets/fonts/CircularStd-Book.otf");
	font-weight: 450;
}

@font-face {
	font-family: "CircularStd";
	src: url("./assets/fonts/CircularStd-Medium.otf");
	font-weight: 500;
}

@font-face {
	font-family: "CircularStd";
	src: url("./assets/fonts/CircularStd-Bold.otf");
	font-weight: bold;
}

@font-face {
	font-family: "CircularStd";
	src: url("./assets/fonts/CircularStd-Black.otf");
	font-weight: 900;
}

*,
html {
	font-size: 14px;
	font-family: "CircularStd", sans-serif;
	margin: 0;
}

.spacer_shared_container {
	padding-bottom: 100px;
}

* {
	scrollbar-color: #a5adba transparent !important;
	scrollbar-width: thin !important;
}

*::-webkit-scrollbar {
	max-width: 7px;
}

*::-webkit-scrollbar:vertical {
	height: 7px;
	max-width: 7px;
}

*::-webkit-scrollbar-thumb {
	background-color: #a5adba;
	border-radius: 2.5px;
	height: 50px;
}

*::-webkit-scrollbar-track {
	background: transparent;
	border-bottom-right-radius: 16px;
	display: none;
}

*::-webkit-scrollbar-thumb:vertical {
	height: 7px !important;
}

*::-webkit-scrollbar-thumb:hover {
	height: auto;
}

*::-webkit-scrollbar-thumb {
	transition: height 0.2s;
}

:root {
	/* base colors */
	--primary: #00875a;
	--secondary: #00875a;
	--danger: #de350b;

	/* shades of green */
	--light-green: #abf5d1;
	--lighter-green: #e3fcef;
	--light-green-color: #27cc86;
	--text-green: #00875a;
	--dark-green: #245d51;
	--warning: #ffab00;
	--warning-hover: #ffc400;
	--primary-hover: #00d28c;
	--blue-custom: #0052cc;
	--purple-custom: #c0b6f2;
	--light-blue: #b3d4ff;
	--neutral-border: #ebecf0;
	--neutral-light: #dfe1e6;
	--svg-blue: #0747a6;
	--yellow-accent: rgba(220, 189, 24, 0.5);

	/* random */
	--border-stoke: #dfe1e6;
	--overlay: rgba(9, 30, 66, 0.54);
	--light-red: rgba(255, 116, 82, 1);
	--transparent-white: rgba(255, 255, 255, 0.4);
	--transparent-grey: #7a869a;

	/* navigation */
	--active-bg-link: #deebff;

	/* text color variations */
	--text-color: #091e42;
	--alt-text-color: #253858;
	--alt-text-color-tint: #172b4d;
	--alt-text-color-3: #505f79;
	--light-alt-text-color: #344563;
	--light-text-color: #6b778c;
	--light-text-color-2: #42526e;
	--light-text-color-3: #8993a4;
	--light-gray: #f4f5f7;
	--gray: #999999;
	--gray500: #667085;
	--darker-gray: #344563;
	--dark-bg-text-color: #ffffff;
	--on-surface-dark-high-emphasis: rgba(24, 24, 25, 0.9);
	--on-surface-dark-low-emphasis: rgba(24, 24, 25, 0.42);
	--light-yellow-custom: #fff0b3;
	--orange-custom: #ff991f;
	--purple-text-color: #5243aa;
	--text-color-varation: rgba(171, 245, 209, 0.384);
	--text-teal: #00a3bf;
	--text-neutral: #42526e;
	--banner-color: #d18c00;
	--timer-bg-color: #ff8b00;
	--ds-text-selected: var(--primary);
	/* states */
	--disabled: #f4f5f7;
	--disabled-tint: #cce1ff;

	/* app background */
	--neutral-10: #fafbfc;
	--neutral-20: #f4f5f7;
	--white-color: #ffffff;
	--neutral-30: #5e6c84;

	--neutral-n-30: #ebecf0;

	/*checkbox border*/
	--checkbox-border: #42526e;

	/*Landing page accent colors*/
	---landing-accent: rgba(220, 189, 24, 0.5);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	color: var(--text-color);
	font-weight: bold;
	font-family: "CircularStd";
	margin: 0;
}

h1 {
	font-size: 3.43rem;
	line-height: 48px;
}

h2 {
	font-size: 2.28rem;
	line-height: 40px;
}

h3 {
	font-size: 1.71rem;
	line-height: 32px;
}

h4 {
	font-size: 1.36rem;
	line-height: 24px;
}

h5 {
	font-size: 1.14rem;
	line-height: 24px;
}

h6 {
	font-size: 0.86rem;
	line-height: 16px;
}

p {
	font-style: normal;
	font-weight: 450;
}

label {
	margin: 0 !important;
}

button.clickable {
	cursor: pointer;
	padding: 0px;
	margin: 0px;
	background: none;
	border: none;
	color: var(--primary);
}

/* Media Queries */
@media (max-width: 1000px) {
	html {
		font-size: 14px;
	}

	body {
		font-size: 14px;
	}

	h1,
	h2 {
		font-size: 1.36rem;
		line-height: 1.36rem;
	}

	h3,
	h4 {
		font-size: 1.14rem;
	}

	p {
		font-size: 1rem;
		line-height: 32px;
	}
}

/* utility classes */
.u-danger {
	color: var(--danger);
}

.u-success {
	color: var(--secondary);
}

.u-alt-text-color {
	color: var(--alt-text-color);
}

.css-zzezwn-ButtonBase {
	background-color: var(--primary) !important;
}

.text-bold {
	font-weight: bold !important;
}

.text-danger {
	color: var(--danger) !important;
}

.text-grey {
	color: var(--gray) !important;
}

/* Avatar */
.sb-avatar__image {
	object-fit: cover;
	object-position: top;
}

.css-127ggxn-TabListWithMode [role="tab"]:hover {
	color: var(--primary-hover) !important;
}

/* aui-toast */
#aui-flag-container {
	z-index: 10000 !important;
}

.text-primary {
	color: var(--primary) !important;
}

.w-50 {
	width: 50%;
}

/* Bootstrap print responsiveness */
@media print and (max-width: 768px) {
	.col-12 {
		width: 50% !important;
	}

	.col-8 {
		width: 60% !important;
	}

	.col-3 {
		width: 40% !important;
	}
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
	border-color: var(--primary);
	box-shadow: 1px 1px 1px transparent;
	outline: 0 none;
}