.red-global-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
	height: 72px;
	background-color: white;
	box-shadow: 0px 2px 6px rgba(193, 199, 208, 0.4);
	position: sticky;
	z-index: 5;
	top: 0;
}

.red-hamburger-menu {
	padding: 0;
	margin: 0;
	background: none;
	border: none;
}

.red-current-module {
	border-left: 1px solid #c1c7d0;
	padding-left: 16px;
}

.red-global-logo {
	margin: 0 16px;
	width: 200px;
}

.red-global-logo img {
	width: 150px;
}

.red-global-logo img:nth-child(1) {
	display: inline;
}

.red-global-logo img:nth-child(2) {
	display: none;
}

.red-current-module {
	font-style: normal;
	font-weight: 450;
	font-size: 1.14rem;
	line-height: 24px;
	color: var(--text-color);
}

.global-avatar span {
	font-size: 0.86rem;
	font-weight: 500px;
}

.topnav-btn {
	cursor: pointer;
	padding: 10px 10px;
	position: absolute;
	right: 20px;
}

.topnav-dropdown-box {
	width: 328px;
	border-radius: 3px;
	padding: 20px, 16px;
}

.topnav-dropdown a {
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-decoration: none;
}

.res-role {
	width: 300px;
	min-height: 244px;
	background: var(--dark-bg-text-color);
	box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
		0px 0px 1px rgba(9, 30, 66, 0.31);
	border-radius: 3px;
}

.res-user {
	font-style: normal;
	font-weight: bold;
	font-size: 0.86rem;
	line-height: 12px;
	color: var(--light-text-color);
	padding: 28px 0 16px 20px;
	text-transform: capitalize;
}

.res-role-txt {
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
	color: #172b4d;
	padding: 0 0 8px 20px;
	cursor: pointer;
	text-decoration: none;
}

.res-role-txt:hover {
	text-decoration: none;
}

.res-roles {
	padding: 0 20px 12px 20px;
	cursor: pointer;
}

.res-roles:hover {
	transform: scale(1.01);
}

.res-roles p {
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
	color: #172b4d;
	margin-left: 12px;
}

.res-wrapper {
	border-bottom: 2px solid #dfe1e6;
}

.res-active-role {
	color: #0747a6;
}

.dropdown-menu {
	transition: all 0.5s;
	overflow: hidden;
	transform-origin: top center;
	transform: scale(1, 0);
	display: block;
}

.dropdown-menu:hover {
	transform: scale(1);
}

.global-landing-page-link-container {
	display: flex;
	align-items: center;
}

.cancel-button {
	cursor: pointer;
	margin-left: 30px;
	border: none;
	background-color: transparent;
	margin-top: 30px;
	display: none;
}

.global-landing-page-links {
	border: none;
	background-color: transparent;
	margin-right: 16px !important;
	font-size: 1rem;
	color: #7a869a;
	cursor: pointer;
}

.global-landing-page-links-active {
	color: #253858;
}

.responsive-hamburger-menu {
	display: none;
}

.retract-navigation {
	top: 0% !important;
}

.gm-view-all {
	text-decoration: none;
	font-size: 12px;
}

.gm-notification-container {
	position: relative;
	display: inline-block;
	margin-right: 1.2rem;
}

.gm-notification-container button {
	position: relative;
	background: none;
	border: none;
}

.gm-notification-container button span {
	display: inline-block;
	position: absolute;
	background: var(--light-red);
	right: 0;
	color: var(--white-color);
	font-size: 0.85rem;
	border-radius: 50%;
	width: 1.4rem;
	max-width: 1.4rem;
	overflow: hidden;
	animation: "breathing-light" 1.5s linear infinite;
	-ms-animation: "breathing-light" 1.5s linear infinite;
	-webkit-animation: "breathing-light" 1.5s linear infinite;
	-moz-animation: "breathing-light" 1.5s linear infinite;
	-o-animation: "breathing-light" 1.5s linear infinite;
}

.gm-notification-contents {
	position: absolute;
	right: 0px;
	width: 420px;
	max-height: 580px;
	overflow: scroll;
	max-width: 90vw;
	box-shadow: 0px 0px 1px 0px #091e424f;
	box-shadow: 0px 10px 18px 0px #091e4226;
	background: var(--white-color);
	z-index: 1000;
}

.gm-notification-contents-style > div {
	border-bottom-width: 2px !important;
}

.gm-notification-contents-style h3 {
	font-size: 1rem;
}

.gm-notification-contents-style h3 ~ span {
	font-size: 0.75rem;
	color: var(--blue-custom);
}

.gm-notification-contents-style ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.gm-notification-contents-style li {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	gap: 10px;
	cursor: pointer;
	min-height: 100px;
}

.gm-notification-contents-style li::before {
	display: inline-block;
	flex-shrink: 0;
	content: " ";
	background: var(--light-red);
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-top: 0.2rem;
	animation: "breathing-light" 1.5s linear infinite;
	-ms-animation: "breathing-light" 1.5s linear infinite;
	-webkit-animation: "breathing-light" 1.5s linear infinite;
	-moz-animation: "breathing-light" 1.5s linear infinite;
	-o-animation: "breathing-light" 1.5s linear infinite;
}

.gm-notification-read {
	background: var(--light-gray);
}

li.gm-notification-read::before {
	background: var(--gray500);
	animation: none;
	-ms-animation: none;
	-webkit-animation: none;
	-moz-animation: none;
	-o-animation: none;
}

.gm-notification-contents-style h4 {
	font-size: 0.85rem;
	font-weight: 700;
}

.gm-notification-contents-style h4 ~ p {
	font-size: 0.85rem;
}

.gm-notification-contents-style h4 ~ p:last-of-type {
	color: var(--light-text-color-3);
}

.gm-notification-contents-style ul ~ div {
	font-size: 0.75rem;
	color: var(--light-text-color-3);
}

.gm-notification-container ::-webkit-scrollbar {
	width: 5px;
}

.gm-notification-container ::-webkit-scrollbar-track {
	display: none;
}

.gm-notification-container ::-webkit-scrollbar-thumb {
	background-color: var(--border-stoke);
	border-radius: 10px;
	border: 5px solid var(--border-stoke);
}

@media (max-width: 834px) {
	.responsive-hamburger-menu {
		display: block;
	}

	.global-landing-page-link-container {
		flex-direction: column;
		align-items: flex-start;
		background-color: #fff;
		position: fixed;
		width: 100%;
		transition: 0.3s ease-in-out;
		height: 100%;
		top: -100%;
		left: 0;
		z-index: 5;
	}

	.global-landing-page-links {
		font-size: 1.3rem;
		margin-right: 0;
		margin-left: 30px;
		margin-top: 30px;
	}

	.cancel-button {
		display: block;
	}
}

@media (max-width: 545px) {
	.red-global-logo img:nth-child(1) {
		display: none;
	}

	.red-global-logo img:nth-child(2) {
		display: inline;
	}

	.red-global-logo {
		margin: 0 8px 0 14px;
	}

	.red-current-module {
		padding-left: 8px;
	}

	.red-global-menu {
		padding: 0 12px;
		height: 64px;
		background-color: white;
	}

	.red-current-module {
		font-size: 1rem;
		line-height: 20px;
	}
}

@media (max-width: 322px) {
	.red-global-logo {
		width: 150px;
	}
}
