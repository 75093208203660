.container header {
	display: flex;
	align-items: center;
	justify-content: center;
}

.green_text {
	text-align: center;
	color: var(--primary);
	text-transform: uppercase;
	margin-top: 20px;
}

.info_container {
	width: 100%;
	max-width: 1084px;
	display: flex;
	flex-wrap: wrap;
	margin: 0px auto;
}

.info_container .gridItem {
	width: 33.33%;
	display: flex;
	align-items: flex-start;
	column-gap: 10px;
	margin-top: 20px;
}

.info_container .gridItem span:first-of-type {
	text-transform: capitalize;
}

.info_container .gridItem span:last-of-type {
	width: 200px;
}
