.auth_container {
	background-color: var(--neutral-10);
	text-align: center;
	position: relative;
	z-index: 400;
	border-radius: 8px;
}
.auth_card {
	width: 90vw;
	max-width: 552px;
	border: 1px solid var(--border-stoke);
	border-radius: 8px;
	position: relative;
}

.auth_card > div:first-of-type img {
	cursor: pointer;
}

.auth_main_header {
	font-size: 1.5rem !important;
	color: var(--text-color);
	line-height: 32px;
	margin-top: 2rem;
}

.auth_sub_header {
	font-size: 0.875rem;
	color: var(--text-color);
	line-height: 20px;
	margin-top: 1rem;
}

.auth_label {
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
}

.link_class {
	font-size: 1rem;
	background-color: transparent;
	color: var(--primary);
	font-weight: 450;
	text-decoration: none !important;
}

.logo {
	width: 89px;
	margin: auto;
}

.logo img {
	width: 100%;
	cursor: pointer;
}

.otp_input_style {
	border: 1px solid var(--border-stoke);
	border-radius: 4px;
	height: 3.7rem;
	width: 3.7rem !important;
}

.full {
	width: 100% !important;
}

.christmasLightContainer {
	position: absolute;
	z-index: 20;
	top: -30px;
	left: 0;
	width: 100%;
}

.light {
	width: 100%;
}

.background {
	position: relative;
	height: 100vh;
	background-color: var(--primary);
}

.white {
	background-color: var(--white-color);
	width: 395px;
	flex: 1;
}

.background img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	flex: 3;
}

.forgot_login_content {
	position: absolute;
	z-index: 3;
	top: 50%;
	left: 50%;
	transform: translate(-40vw, -50%);
}

.otp_login_content {
	position: absolute;
	z-index: 3;
	top: 50%;
	left: 50%;
	transform: translate(-40vw, -50%);
}

/* new Auth background wrapper style */

.background {
	position: relative;
	display: flex;
	height: 100vh;
}

.white {
	background-color: var(--white-color);
	width: 395px;
	flex: 1;
}

.background img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	flex: 3;
}

@media (max-width: 768px) {
	.light {
		top: -15px;
	}

	.forgot_login_content {
		transform: translate(-50%, -50%);
	}

	.auth_sub_header {
		margin-top: 0px;
		padding: 0 1.2rem;
	}

	.form_wrapper {
		padding: 0 1.2rem 0.7rem 1.2rem !important;
	}

	.form_footer_wrapper {
		padding: 0.5rem 1.2rem !important;
	}
}
