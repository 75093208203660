.body {
	position: relative;
	height: 100%;
}

.container {
	background: url("../../../assets/images/landing.webp") center;
	background-color: var(--primary);
	background-size: cover;
	min-height: 60vh;
	background-repeat: no-repeat;
	position: relative;
	flex-direction: column;
}

.title h1 {
	width: 45%;
	line-height: 1.3;
	color: var(--white-color);
}
.title p {
	font-size: 1.3rem;
	font-weight: lighter;
	color: var(--white-color);
	line-height: 2;
	width: 45%;
	margin-top: 1.2rem;
	text-align: center;
}
.secondSection {
	background-color: var(--light-gray);
	padding-top: 8rem;
	padding-bottom: 5.8rem;
}
.secondSection h2 {
	font-size: 2.5rem;
	margin-bottom: 4rem;
}
.cardContainer {
	padding: 0 18%;
}
.infoDetails {
	width: 65.4rem;
	height: 39.5rem;
	background-color: var(--white-color);
	padding: 64px 56px;
}
.infoDetails h6 {
	font-size: 1.2rem;
	line-height: 1.5rem;
	margin-bottom: 1rem;
}
.infoDetails h5 {
	font-style: normal;
	font-weight: 450;
}
.infoDetails p,
li {
	line-height: 1.5rem;
}
.infoDetails a {
	text-decoration: none;
	display: block;
	line-height: 12px;
	color: var(--text-green);
	font-weight: 500;
	margin-top: 12px;
}
.infoDetails a:hover {
	color: var(--light-green-color);
}

.sectionsContainer {
	max-width: 1280px;
	padding: 20px;
	padding-top: 80px;
	margin: 0 auto;
}

.sectionsContainer section:nth-of-type(1) > * {
	margin-bottom: 20px;
}

.notice {
	display: inline-block;
	background-color: var(--transparent-white);
	color: var(--white-color);
	padding: 5px 15px 5px 12px;
	border-radius: 100px;
	animation: tilt-shaking 0.35s infinite;
	animation-iteration-count: 7;
	font-size: 0.75rem;
}

.notice span {
	display: inline-block;
	border-radius: 100px;
	background-color: var(--light-red);
	padding: 5px 10px;
	margin-right: 10px;
	font-size: 0.75rem;
}

.home_footer {
	height: 8vh;
	background-color: var(--white-color);
	padding: 0 5%;
}

.call_centre_number {
	font-weight: 500;
	color: var(--alt-text-color-tint);
}

.call_centre_number > span:first-of-type {
	color: var(--light-text-color);
}

.call_centre_number > span:last-of-type {
	color: var(--text-green);
}

.fab {
	background-color: var(--primary);
	width: 2.7rem;
	height: 2.7rem;
	border-radius: 4px;
	position: absolute;
	bottom: 4.6%;
	right: 1.7%;
	transition: all 0.3s ease;
}
.fab:hover {
	bottom: 5%;
	cursor: pointer;
}

/* .sectionsContainer section:nth-of-type(1) h1 {
	color: var(--white-color);
	line-height: 1.36rem;
}

.sectionsContainer section:nth-of-type(1) p {
	color: var(--white-color);
	font-weight: 300;
	line-height: 20px;
}

.cardsContainer {
	margin-top: 20px;
}

.card {
	display: inline-block;
	background: var(--white-color);
	border-radius: 6px;
	padding: 40px;
}

.card > * {
	margin-bottom: 15px;
}

.card p {
	color: var(--text-neutral);
}

.cardLinks {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 30px;
}

.cardLinks a {
	text-decoration: none;
	color: var(--text-teal);
	font-weight: 500;
}

.cardLinks a:hover {
	text-decoration: underline;
	color: var(--text-teal);
}

@keyframes tilt-shaking {
	0% {
		transform: translate(0, 0) rotate(0deg);
	}
	25% {
		transform: translate(5px, 5px) rotate(5deg);
	}
	50% {
		transform: translate(0, 0) rotate(0eg);
	}
	75% {
		transform: translate(-5px, 5px) rotate(-5deg);
	}
	100% {
		transform: translate(0, 0) rotate(0deg);
	}
} */

@media (max-width: 768px) {
	/* .notice,
	.notice span {
		font-size: 1rem;
	}

	.sectionsContainer {
		padding: 40px;
		padding-top: 130px;
	}

	.sectionsContainer section:nth-of-type(1) > * {
		margin-bottom: 30px;
	}

	.sectionsContainer section:nth-of-type(1) h1 {
		line-height: 63px;
	}

	.sectionsContainer section:nth-of-type(1) p {
		line-height: 25px;
	}

	.cardsContainer {
		margin-top: 60px;
	}

	.card {
		padding: 50px;
	} */

	.title h1 {
		font-size: 2rem;
		color: var(--white-color);
		line-height: 1.2;
		width: 100%;
	}
	.container {
		padding: 0 2%;
	}
	.home_footer p {
		font-size: 0.7rem;
		line-height: 1.2;
	}
	.title p {
		font-size: 1rem;
		width: 90%;
	}
	.infoDetails {
		width: 100%;
		height: 100%;
		background-color: var(--white-color);
		/* padding: 64px 56px; */
	}
	.cardContainer {
		flex-wrap: wrap;
		padding: 0 8%;
	}
	.infoDetails p,
	ul {
		text-align: justify;
		text-justify: inter-word;
		/* padding: 64px 56px; */
	}
	.infoSectionContainer {
		gap: 30px;
	}
	.secondSection {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.secondSection h2 {
		font-size: 2rem;
		padding: 0 14px;
		margin-bottom: 2.5rem;
	}
}

@media (min-width: 1200px) {
	.sectionsContainer {
		padding: 50px;
		padding-top: 160px;
	}

	.sectionsContainer section:nth-of-type(1) h1 {
		width: 70%;
		font-size: 4.29rem;
		line-height: 70px;
	}

	.sectionsContainer section:nth-of-type(1) p {
		width: 55%;
		font-size: 1.29rem;
	}
}
