.accordionCard {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	padding: 0 12px;
	word-wrap: break-word;
	background-clip: border-box;
}

.accordionDetails {
	width: 100% !important;
	height: 100%;
	background-color: var(--white-color);
	padding: 1rem 1.5rem 2rem;
	min-height: 1px;
}

.accordionDetails p,
ul {
	text-align: justify;
	text-justify: inter-word;
	line-height: 1.5rem;
}

.accordionDetails h5,
li {
	font-size: 0.95rem;
	font-style: normal;
	font-weight: 450;
}

.accordionDetails h6 {
	font-size: 1rem;
	line-height: 1.5rem;
	margin-bottom: 1rem;
}

.accordionDetails a {
	text-decoration: none;
	display: block;
	line-height: 12px;
	color: var(--text-green);
	font-size: 0.95rem;
	font-weight: 500;
	margin-top: 12px;
	width: fit-content;
}
.accordionDetails a:hover {
	color: var(--light-green-color);
}
